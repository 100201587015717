/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from '@illumio-shared/utils/intl';
import {webStorageUtils} from '@illumio-shared/utils';
import {fillUserInfo} from 'containers/RBAC/RBACUtils';
import {isKubernetesSupported} from 'containers/App/AppState';

export const diffStatusIcon = {
  create: 'added',
  delete: 'removed',
  update: 'modified',
};

export const getTypeAndRoutes = (count = 0) => {
  return {
    virtual_services: {
      typeLabel: intl('Common.VirtualService'),
      tally: intl('Provision.TallyLabel.VirtualServices', {count}),
      tallyHistory: intl('Version.Detail.TallyLabel.ChangedVirtualServices', {count}),
      route: 'virtualServices.item.view',
    },
    enforcement_boundaries: {
      typeLabel: intl('Rulesets.DenyRules'),
      tally: intl('Rulesets.DenyRulesAuto', {count}),
      tallyHistory: intl('Version.Detail.TallyLabel.ChangedEnforcementBoundaries', {count}),
      route: 'boundaries.item.view',
    },
    firewall_settings: {
      typeLabel: intl('Common.Setting'),
      name: intl('Common.SecuritySettings'),
      tally: intl('Provision.TallyLabel.Settings', {count}),
      tallyHistory: intl('Version.Detail.TallyLabel.ChangedSettings', {count}),
      route: 'securitysettings.section',
      param: {section: 'general'},
    },
    ip_lists: {
      typeLabel: intl('Common.IPList'),
      tally: intl('Provision.TallyLabel.IPLists', {count}),
      tallyHistory: intl('Version.Detail.TallyLabel.ChangedIPLists', {count}),
      route: 'iplists.item.view',
    },
    label_groups: {
      typeLabel: intl('Labels.Group'),
      tally: intl('Provision.TallyLabel.LabelGroups', {count}),
      tallyHistory: intl('Version.Detail.TallyLabel.ChangedLabelGroups', {count}),
      route: 'labelGroups.item',
    },
    rule_sets: {
      typeLabel: intl('Common.Ruleset'),
      tally: intl('Provision.TallyLabel.Rulesets', {count}),
      tallyHistory: intl('Version.Detail.TallyLabel.ChangedRulesets', {count}),
      route: 'rulesets.item',
      param: {tab: 'intrascope'},
    },
    services: {
      typeLabel: intl('Common.Service'),
      tally: intl('Map.Traffic.ServiceCount', {count}),
      tallyHistory: intl('Version.Detail.TallyLabel.ChangedServices', {count}),
      route: 'services.item.view',
    },
    secure_connect_gateways: {
      typeLabel: intl('Provision.TallyLabel.SecureConnect', {count: 1}),
      tally: intl('Provision.TallyLabel.SecureConnect', {count}),
      tallyHistory: intl('Version.Detail.TallyLabel.ChangedSecureConnect', {count}),
      route: 'secureGateways.item.view',
    },
    virtual_servers: {
      typeLabel: intl('Common.VirtualServer'),
      tally: intl('VirtualServers.ByCount', {count}),
      tallyHistory: intl('Version.Detail.TallyLabel.ChangedVirtualServers', {count}),
      route: 'virtualServers.item',
    },
    essential_service_rules: {
      typeLabel: intl('Common.Setting'),
      name: intl('Settings.EssentialServiceRules'),
      tally: intl('Provision.TallyLabel.Settings', {count}),
      tallyHistory: intl('Version.Detail.TallyLabel.ChangedSettings', {count}),
      route: 'essentialservicerules',
      param: {section: 'draft'},
    },
    settings: {
      typeLabel: intl('Common.Setting'),
      tally: intl('Provision.TallyLabel.Settings', {count}),
      tallyHistory: intl('Version.Detail.TallyLabel.ChangedSettings', {count}),
    },
  };
};

export const getTypeAndRoute = (type, count) => getTypeAndRoutes(count)[type];

export const getProvisionSelection = () => {
  const storedGeneralSelection = _.find(
    webStorageUtils.getSessionItem('GeneralSelection', {remove: true}) || [],
    selection => selection.key === 'provision',
  );

  return storedGeneralSelection ? storedGeneralSelection.value : {};
};

export const getProvisionMenuCounts = (pendingObjects = {}) =>
  Object.entries(pendingObjects).reduce((result, [objType, items]) => {
    // we need to collapse essential service rules & firewall settings in to a single category: settings
    // both are arrays with 1 object
    if (objType === 'essential_service_rules' || objType === 'firewall_settings') {
      result.settings = (result.settings ?? 0) + 1;
    } else {
      result[objType] = items.filter(item => item.caps).length;
    }

    return result;
  }, {});

export const getProvisionCounts = (items = []) => {
  const counts = {
    rule_sets: 0,
    services: 0,
    ip_lists: 0,
    settings: 0,
    ...(__ANTMAN__
      ? {
          secure_connect_gateways: 0,
          label_groups: 0,
          ...(isKubernetesSupported && {virtual_services: 0}),
        }
      : {
          ...(isKubernetesSupported && {virtual_services: 0}),
          label_groups: 0,
          virtual_servers: 0,
          secure_connect_gateways: 0,
          enforcement_boundaries: 0,
        }),
  };

  _.each(items, item => {
    switch (item.data?.type || item.object_type) {
      case 'virtual_services':
        counts.virtual_services++;
        break;
      case 'rule_sets':
      case 'rulesets':
        counts.rule_sets++;
        break;
      case 'services':
        counts.services++;
        break;
      case 'ip_lists':
        counts.ip_lists++;
        break;
      case 'label_groups':
        counts.label_groups++;
        break;
      case 'virtual_servers':
        counts.virtual_servers++;
        break;
      case 'firewall_settings':
      case 'essential_service_rules':
        counts.settings++;
        break;
      case 'secure_connect_gateways':
        counts.secure_connect_gateways++;
        break;
      case 'enforcement_boundaries':
        counts.enforcement_boundaries++;
        break;
      // no default
    }
  });

  return counts;
};

export const formatCountsForTally = (counts = {}, {versionLabel = false} = {}) =>
  Object.keys(counts)
    .filter(item => counts[item] > 0)
    .map(
      item =>
        counts[item] && {
          children: versionLabel
            ? getTypeAndRoute(item, counts[item]).tallyHistory
            : getTypeAndRoute(item, counts[item]).tally,
          count: counts[item],
        },
    );

export const calculatedItemTypeStaticValues = (counts = {}) =>
  Object.keys(counts).map(item => getTypeAndRoute(item).typeLabel);

export const getProvisionCountsTotal = (counts = {}) => Object.values(counts).reduce((prev, cur) => prev + cur, 0);

export const fillPolicyObjUserInfo = (versions, usersMap) =>
  Object.entries(versions).reduce(
    (result, [pversion, data]) => ({
      ...result,
      [pversion]: data && {
        ...data,
        createdBy: fillUserInfo(usersMap, data.created_by),
        updatedBy: fillUserInfo(usersMap, data.updated_by),
      },
    }),
    {},
  );

export const getPolicyVersions = (detail = {}, pversion) => {
  // When isOldVersion is true then the pversion is numeric
  const isOldVersion = Number.isInteger(Number(pversion));
  const {active, draft, oldPversionObj, oldPrevPversionObj} = detail;

  // Scenario to describe oldPversionObj.
  // When viewing page: https://devtest201:8443/#/iplists/136/941 an API call to
  // 'https://devtest201:8443/api/v2/orgs/1/sec_policy/941/ip_lists/136' is invoked to get 'oldPversionObj'.

  //  941 - is the pversion on uri: https://devtest201:8443/api/v2/orgs/1/sec_policy/941/ip_lists/136
  //  136 - is the ip_lists id on uri: https://devtest201:8443/api/v2/orgs/1/sec_policy/941/ip_lists/136
  // When 'oldPversionObj', !draft , and !active are undefined then this pversion is DELETED (aka deleted policy object).
  // When 'oldPversionObj' is undefined, draft and active both exist then this pversion has been RESTORED (aka restored policy object).

  const pversionObjIsDeleted = !oldPversionObj && Boolean(oldPrevPversionObj);

  const versions = {draft, active};

  if (pversionObjIsDeleted) {
    // show content of last valid version for deleted policy object and restored policy object
    versions.pversionObj = oldPrevPversionObj;
  } else if (isOldVersion) {
    versions.pversionObj = oldPversionObj;
    versions.prevPversionObj = oldPrevPversionObj;
  } else if (pversion === 'draft') {
    versions.pversionObj = draft;
    versions.prevPversionObj = active;
  } else if (draft && !draft.update_type) {
    versions.pversionObj = draft;
  } else {
    versions.pversionObj = active;
  }

  return {versions, isOldVersion, pversionObjIsDeleted};
};

export const getModifiedDateAndBy = (items = []) => {
  const values = [...items];

  if (values.length === 0) {
    return;
  }

  let {updated_at, updated_by} = values.shift() ?? {};

  values.forEach(({updated_at: updatedAt, updated_by: updatedBy} = {}) => {
    if (new Date(updated_at).getTime() < new Date(updatedAt).getTime()) {
      updated_at = updatedAt;
      updated_by = updatedBy;
    }
  });

  return {updated_at, updated_by};
};
